
//Here's the api connector to communicate with the wordpress
//https://localhost/cms-service/index.php/wp-json/v1/home/id/23

import { get } from '@kit/utils/APIV1.js'

export const home = async() => {
  return get(`rvitpage/id/437`)
}
export const rvitpage = async(slug, urlsection) => {
  return get(`rvitpage/slug/${slug}/section/${urlsection}`)
}