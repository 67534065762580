
//Cancel out the annoying formats for the phone-number
//https://stackoverflow.com/questions/3736807/how-do-i-remove-the-blue-styling-of-telephone-numbers-on-iphone-ios
export const meta = (meta) => {
  meta.addMetaItem([
    { name:'format-detection', content: 'telephone=no' },
  ])
}
  
export const extraJS = null
  
export const externalCSS = [
  "https://fonts.googleapis.com/css?family=Lato:100,100italic,300,300italic,400,400italic,700,700italic,900,900italic%7COswald:200,300,400,500,600,700%7CMontserrat:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic%7CPoppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic"
]