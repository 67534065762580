<script>
import { defineComponent, onMounted, ref, watch, inject } from "vue"
import NavLink from '@components/NavLink.vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: "NavBar",
  setup() {

    let previousNavOption = null
    let previousNavList = null
    const route = useRoute()
    const bannerOpen = ref(true)

    const closeList = (dropDownList, navTarget) => {
      dropDownList.setAttribute("class", "dropdown-list w-dropdown-list")
      dropDownList.setAttribute("aria-expanded", "false")
      navTarget["__dropdownToggle"] = false
    }
    const openList = (dropDownList, navTarget) => {
      dropDownList.setAttribute("class", "dropdown-list w-dropdown-list w--open")
      dropDownList.setAttribute("aria-expanded", "true") 
      navTarget["__dropdownToggle"] = true 
    }

    const linkClass = (path) => {
      return route.path == path ? 'dropdown-link w-dropdown-link w--current' : 'dropdown-link w-dropdown-link'
    }

    const closeBanner = () => {
      bannerOpen.value = false
    }

    const clickReachedDocument = (_e) => {
      if(previousNavOption) {
        closeList(previousNavOption, previousNavList)
      }
      previousNavOption = null 
      previousNavList = null
    }

    const navToggleClicked = (e) => {
      const { currentTarget } = e
      e.stopPropagation();

      let dropDownList = currentTarget["__dropdown"] 

      if(!dropDownList) {
        dropDownList = currentTarget.parentNode.querySelector("nav.w-dropdown-list")
        currentTarget["__dropdown"] = dropDownList
      }
      if(!dropDownList) {
        return
      }

      if(!currentTarget["__dropdownToggle"]) {
        if(previousNavOption) {
          closeList(previousNavOption, previousNavList)
        }
        openList(dropDownList, currentTarget)
        previousNavOption = dropDownList
        previousNavList = currentTarget
      
      } else {
        closeList(dropDownList, currentTarget)
      }
    }

    onMounted(() => {
      const navToggleTargets = document.querySelectorAll(".w-dropdown-toggle")
      for(let i=0; i<navToggleTargets.length; i++) {
        const navTarget = navToggleTargets[i]
        navTarget.addEventListener("click", navToggleClicked)
      }
      document.addEventListener("click", clickReachedDocument)
    })


    return { closeBanner, bannerOpen }
  },

  components: {
    NavLink
  }
});
</script>


<template>
  <div v-if="bannerOpen" id="noticebarid" class="notice-bar wf-section">
    <div class="content-wrapper-2 w-container">
      <div class="notice-box">
        <div class="notice-text-box">
          <div href="" class="notice-text">&nbsp;Stay up-to-Date with our latest &nbsp; <strong>Announcements</strong> - <a href="/#announcements" class="link-white">View Now</a>
          </div>
        </div>
        <a href="#" data-ix="hide-notice-bar" id="miniclosetest" class="mini-close w-inline-block">
          <img src="/cms-static-assets/2023/01/5fe1178443d559a1897d9241_Close-mini.png" width="10" alt="" @click="closeBanner" class="mini-close-x">
        </a>
      </div>
    </div>
  </div>

  <div data-collapse="medium" data-animation="default" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar transparent w-nav">
    <div class="full-container center">
      <router-link to="/" class="logo-brand inline w-nav-brand" aria-label="home">
        <img src="@images/logo_rvit_no_center.svg" alt="" class="image-logo">
      </router-link>
      <nav role="navigation" class="nav-menu w-nav-menu">
        <NavLink href="/" class="nav-link w-nav-link">Home</NavLink>
        <NavLink href="/government/documents-forms" class="nav-link w-nav-link">Forms</NavLink>
        <div data-delay="0" data-hover="false" class="w-dropdown">
          <div class="nav-link dropdown w-dropdown-toggle" id="w-dropdown-toggle-0" aria-controls="w-dropdown-list-0" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
            <div class="text-block-22">About</div>
            <div class="icon-6 w-icon-dropdown-toggle" aria-hidden="true"></div>
          </div>
          <nav class="dropdown-list w-dropdown-list" id="w-dropdown-list-0" aria-labelledby="w-dropdown-toggle-0">
            <NavLink href="/about/about-us" class="dropdown-link w-dropdown-link" tabindex="0">About Us</NavLink>
            <NavLink href="/about/po-e-kan" class="dropdown-link w-dropdown-link" tabindex="0">PO-E-'KAN</NavLink>
            <NavLink href="/about/cell-tower-project" class="dropdown-link w-dropdown-link" tabindex="0">Cell Tower Project</NavLink>
            <NavLink href="/about/covid-19-information" class="dropdown-link w-dropdown-link" tabindex="0">Covid-19 Information</NavLink>
            <NavLink href="/about/tower-location-voting" class="dropdown-link w-dropdown-link" tabindex="0">Tower Location Voting</NavLink>
            <div class="div-block--external-links"></div>
            <div class="w-dyn-list">
              <div class="hide-empty w-dyn-empty">
                <div>No items found.</div>
              </div>
            </div>
          </nav>
        </div>
        <div data-delay="0" data-hover="false" class="w-dropdown">
          <div class="nav-link dropdown w-dropdown-toggle" id="w-dropdown-toggle-1" aria-controls="w-dropdown-list-1" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
            <div class="text-block-22">Jobs</div>
            <div class="icon-6 w-icon-dropdown-toggle" aria-hidden="true"></div>
          </div>
          <nav class="dropdown-list w-dropdown-list" id="w-dropdown-list-1" aria-labelledby="w-dropdown-toggle-1">
            <NavLink href="/jobs-rvit/jobs-general-information" class="dropdown-link w-dropdown-link" tabindex="0">General Information</NavLink>
            <div class="w-dyn-list">
              <div role="list" class="w-dyn-items">
                <div role="listitem" class="w-dyn-item">
                  <NavLink href="/job-categories/round-valley-indian-tribes-jobs" class="dropdown-link w-dropdown-link" tabindex="0">RVIT Jobs</NavLink>
                </div>
                <div role="listitem" class="w-dyn-item">
                  <NavLink href="/job-categories/round-valley-indian-health-center-inc-jobs" class="dropdown-link w-dropdown-link" tabindex="0">RVIHC Jobs</NavLink>
                </div>
                <div role="listitem" class="w-dyn-item">
                  <NavLink href="/job-categories/round-valley-indian-housing-authority-jobs" class="dropdown-link w-dropdown-link" tabindex="0">RVIHA Jobs</NavLink>
                </div>
                <div role="listitem" class="w-dyn-item">
                  <NavLink href="/job-categories/hidden-oaks-casino-jobs" class="dropdown-link w-dropdown-link" tabindex="0">Casino Jobs</NavLink>
                </div>
              </div>
            </div>
            <div class="div-block--external-links"></div>
            <div class="w-dyn-list">
              <div class="hide-empty w-dyn-empty">
                <div>No items found.</div>
              </div>
            </div>
          </nav>
        </div>
        <div data-delay="0" data-hover="false" class="w-dropdown">
          <div class="nav-link dropdown w-dropdown-toggle" id="w-dropdown-toggle-2" aria-controls="w-dropdown-list-2" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
            <div>Events</div>
            <div class="icon-6 w-icon-dropdown-toggle" aria-hidden="true"></div>
          </div>
          <nav class="dropdown-list w-dropdown-list" id="w-dropdown-list-2" aria-labelledby="w-dropdown-toggle-2">
            
            <div class="w-dyn-list">
              <div role="list" class="w-dyn-items">
                <div role="listitem" class="w-dyn-item">
                  <NavLink href="/events/tnap" class="dropdown-link w-dropdown-link" tabindex="0">TNAP Gift Cards</NavLink>
                </div>
                <div role="listitem" class="w-dyn-item">
                  <NavLink href="/events/trex" class="dropdown-link w-dropdown-link" tabindex="0">TREX</NavLink>
                </div>
                <div role="listitem" class="w-dyn-item">
                  <NavLink href="/events/native-american-artists" class="dropdown-link w-dropdown-link" tabindex="0">Native American Artists Exhibition</NavLink>
                </div>
                <div role="listitem" class="w-dyn-item">
                  <NavLink href="/events/observed-holidays" class="dropdown-link w-dropdown-link" tabindex="0">Observed Holidays</NavLink>
                </div>
              </div>
            </div>
            <div class="div-block--external-links"></div>
            <div class="w-dyn-list">
              <div class="hide-empty w-dyn-empty">
                <div>No items found.</div>
              </div>
            </div>
            
            <!-- <NavLink href="/events/observed-holidays" class="dropdown-link w-dropdown-link" tabindex="0">Observed Holidays</NavLink>
            <div class="div-block--external-links"></div>
            <div class="w-dyn-list">
              <div class="hide-empty w-dyn-empty">
                <div>No items found.</div>
              </div>
            </div> -->
          </nav>
        </div>
        <div data-delay="0" data-hover="false" class="w-dropdown">
          <div class="nav-link dropdown w-dropdown-toggle" id="w-dropdown-toggle-3" aria-controls="w-dropdown-list-3" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
            <div>Programs</div>
            <div class="icon-6 w-icon-dropdown-toggle" aria-hidden="true"></div>
          </div>
          <nav class="dropdown-list w-dropdown-list" id="w-dropdown-list-3" aria-labelledby="w-dropdown-toggle-3">
            <NavLink href="/programs/program-directory" class="dropdown-link w-dropdown-link" tabindex="0">Program Directory</NavLink>
            <div class="div-block--external-links"></div>
            <div class="w-dyn-list">
              <div role="list" class="w-dyn-items">
                <div role="listitem" class="w-dyn-item">
                  <NavLink href="/program-directory/child-care" class="dropdown-link w-dropdown-link" tabindex="0">Child Care</NavLink>
                </div>
                <div role="listitem" class="w-dyn-item">
                  <NavLink href="/program-directory/environmental-protection-agency" class="dropdown-link w-dropdown-link" tabindex="0">Environmental Protection Agency</NavLink>
                </div>
                <div role="listitem" class="w-dyn-item">
                  <NavLink href="/program-directory/head-start" class="dropdown-link w-dropdown-link" tabindex="0">Head Start</NavLink>
                </div>
                <div role="listitem" class="w-dyn-item">
                  <NavLink href="/program-directory/icwa" class="dropdown-link w-dropdown-link" tabindex="0">ICWA</NavLink>
                </div>
                <div role="listitem" class="w-dyn-item">
                  <NavLink href="/program-directory/nadvsap-dv" class="dropdown-link w-dropdown-link" tabindex="0">NADVSAP (DV)</NavLink>
                </div>
                <div role="listitem" class="w-dyn-item">
                  <NavLink href="/program-directory/natural-resources-department" class="dropdown-link w-dropdown-link" tabindex="0">Natural Resources Department</NavLink>
                </div>
                <div role="listitem" class="w-dyn-item">
                  <NavLink href="/program-directory/real-estate-department" class="dropdown-link w-dropdown-link" tabindex="0">Real Estate Department</NavLink>
                </div>
                <div role="listitem" class="w-dyn-item">
                  <NavLink href="/program-directory/roads-transportation-department" class="dropdown-link w-dropdown-link" tabindex="0">Roads/Transportation Department</NavLink>
                </div>
                <div role="listitem" class="w-dyn-item">
                  <NavLink href="/program-directory/senior-center" class="dropdown-link w-dropdown-link" tabindex="0">Senior Center</NavLink>
                </div>
                <div role="listitem" class="w-dyn-item">
                  <NavLink href="/program-directory/tribal-historic-preservation-office" class="dropdown-link w-dropdown-link" tabindex="0">Tribal Historic Preservation Office</NavLink>
                </div>
                <div role="listitem" class="w-dyn-item">
                  <NavLink href="/program-directory/tribal-police" class="dropdown-link w-dropdown-link" tabindex="0">Tribal Police</NavLink>
                </div>
                <div role="listitem" class="w-dyn-item">
                  <NavLink href="/program-directory/water-association" class="dropdown-link w-dropdown-link" tabindex="0">Water Association</NavLink>
                </div>
                <div role="listitem" class="w-dyn-item">
                  <NavLink href="/program-directory/7-tribes-rock-quarry" class="dropdown-link w-dropdown-link" tabindex="0">7 Tribes Rock Quarry</NavLink>
                </div>
              </div>
            </div>
            <div class="div-block--external-links"></div>
            <div class="w-dyn-list">
              <div role="list" class="w-dyn-items">
                <div role="listitem" class="w-dyn-item">
                  <a href="http://cobell.rvit.org/" class="dropdown-link w-dropdown-link" tabindex="0">Cobell Land Buy-Back Program</a>
                </div>
                <div role="listitem" class="w-dyn-item">
                  <a href="http://www.roundvalleyindianhealthcenter.com/" class="dropdown-link w-dropdown-link" tabindex="0">Round Valley Indian Health Center</a>
                </div>
                <div role="listitem" class="w-dyn-item">
                  <a href="http://rviha.org/" class="dropdown-link w-dropdown-link" tabindex="0">Round Valley Indian Housing Authority</a>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div data-delay="0" data-hover="false" class="w-dropdown">
          <div class="nav-link dropdown w-dropdown-toggle" id="w-dropdown-toggle-4" aria-controls="w-dropdown-list-4" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
            <div>Enterprises</div>
            <div class="icon-6 w-icon-dropdown-toggle" aria-hidden="true"></div>
          </div>
          <nav class="dropdown-list w-dropdown-list" id="w-dropdown-list-4" aria-labelledby="w-dropdown-toggle-4">
            <NavLink href="/enterprises/enterprises-info" class="dropdown-link w-dropdown-link" tabindex="0">Enterprise Info</NavLink>
            <div class="div-block--external-links"></div>
            <a href="tel:1-707-983-6106" class="dropdown-link w-dropdown-link" tabindex="0">Hidden Oaks Convenience Store</a>
            <div class="w-dyn-list">
              <div role="list" class="w-dyn-items">
                <div role="listitem" class="w-dyn-item">
                  <a href="https://www.goldenoaksmotel.com/" class="dropdown-link w-dropdown-link" tabindex="0">Golden&nbsp;Oaks Motel</a>
                </div>
                <div role="listitem" class="w-dyn-item">
                  <a href="https://hiddenoakscasino.com/" class="dropdown-link w-dropdown-link" tabindex="0">Hidden Oaks Casino</a>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div data-delay="0" data-hover="false" class="w-dropdown">
          <div class="nav-link dropdown w-dropdown-toggle" id="w-dropdown-toggle-5" aria-controls="w-dropdown-list-5" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
            <div>Government</div>
            <div class="icon-6 w-icon-dropdown-toggle" aria-hidden="true"></div>
          </div>
          <nav class="dropdown-list w-dropdown-list" id="w-dropdown-list-5" aria-labelledby="w-dropdown-toggle-5">
            <div class="div-block--nav-special-block">
              <div>Legislative</div>
            </div>
            <NavLink href="/government/tribal-council" class="dropdown-link w-dropdown-link" tabindex="0">Tribal Council</NavLink>
            <div class="w-dyn-list">
              <div class="hide-empty w-dyn-empty">
                <div>No items found.</div>
              </div>
            </div>
            <div class="div-block--nav-special-block">
              <div>Executive</div>
            </div>
            <NavLink href="/government/tribal-administration" class="dropdown-link w-dropdown-link" tabindex="0">Tribal Administration</NavLink>
            <div class="w-dyn-list">
              <div class="hide-empty w-dyn-empty">
                <div>No items found.</div>
              </div>
            </div>
            <div class="div-block--nav-special-block">
              <div>Judicial</div>
            </div>
            <NavLink href="/government/tribal-court" class="dropdown-link w-dropdown-link" tabindex="0">Tribal Court</NavLink>
            <div class="div-block--nav-special-block">
              <div>Constitution</div>
            </div>
            <NavLink href="/government/constitution" class="dropdown-link w-dropdown-link" tabindex="0">Constitution</NavLink>
            <div class="w-dyn-list">
              <div class="hide-empty w-dyn-empty">
                <div>No items found.</div>
              </div>
            </div>
            <div class="div-block--nav-special-block">
              <div>Document &amp; Forms</div>
            </div>
            <NavLink href="/government/documents-forms" class="dropdown-link w-dropdown-link" tabindex="0">Documents &amp; Forms</NavLink>
            <NavLink href="/government/agendas" class="dropdown-link w-dropdown-link" tabindex="0">Agendas</NavLink>
            <NavLink href="/government/minutes" class="dropdown-link w-dropdown-link" tabindex="0">Minutes</NavLink>
            <div class="w-dyn-list">
              <div class="hide-empty w-dyn-empty">
                <div>No items found.</div>
              </div>
            </div>
            <div class="div-block--external-links"></div>
            <NavLink href="/government/committees-boards" class="dropdown-link w-dropdown-link" tabindex="0">Committees &amp; Boards</NavLink>
          </nav>
        </div>
        <NavLink href="/contact-us" class="nav-link w-nav-link">Contact</NavLink>
      </nav>
      <div class="menu-button-2 dark w-nav-button" style="-webkit-user-select: text;" aria-label="menu" role="button" tabindex="0" aria-controls="w-nav-overlay-0" aria-haspopup="menu" aria-expanded="false">
        <div class="w-icon-nav-menu"></div>
      </div>
    </div>
    <div class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
  </div>

</template>
