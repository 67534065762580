
<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: "Footer",

  setup() {

    const route = useRoute()

    const showCourtDateForPaths = {
      '/about/about-us': true,
      '/about/covid-19-information': true,
      '/about/tower-location-voting': true,
      '/jobs-rvit/jobs-general-information': true,
      '/job-categories/round-valley-indian-health-center-inc-jobs': true,
      '/job-categories/round-valley-indian-housing-authority-jobs': true,
      '/government/documents-forms': true,
      '/program-directory/tribal-historic-preservation-office': true,
      '/program-directory/tribal-police': true,
      '/program-directory/water-association': true,
      '/government/tribal-council': true,
      '/government/tribal-administration': true,
      '/tribal-court/tribal-court': true,
      '/government/documents-forms': true,
      '/government/agendas': true,
      '/government/minutes': true,
      '/government/committees-boards': true,
      '/contact-us': true
    }

    const showCourtDates = computed(() => {
      const p = route.path 
      return !!showCourtDateForPaths[p]
    })

    return { showCourtDates }

  },

});
</script>


<template>

  <div class="section color red wf-section">
    <div class="full-container w-container">
      <div class="section-tittle-block">
        <h1 class="section-tittle big-transparent white">What They Say?</h1>
        <h1 class="section-tittle full-white">Job Announcements <br>
        </h1>
        <div class="sub-tittle white">The following are job listings for the various Tribal Organizations, Businesses and Departments <strong>
            <em>(listed here for convenience, for detailed information please contact the relevant Organization, Business or Department)</em>
          </strong>. </div>
        <div class="separator-wrapper">
          <div class="small-separator"></div>
          <div class="small-separator center red"></div>
          <div class="small-separator"></div>
        </div>
      </div>
      <div class="w-dyn-list">
        <div role="list" class="collection-list w-dyn-items w-row">
          <div role="listitem" class="w-dyn-item w-col w-col-3">
            <div class="services-wrapper in-blog">
              <div>
                <img src="/cms-static-assets/2022/12/rvit_nature_mountains.jpg" alt="Nature landscape with mountains" 
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 92vw, 22vw" 
                srcset="/cms-static-assets/2022/12/rvit_nature_mountains-500.jpg 500w, 
                /cms-static-assets/2022/12/rvit_nature_mountains-800.jpg 800w, 
                /cms-static-assets/2022/12/rvit_nature_mountains-1080.jpg 1080w, 
                /cms-static-assets/2022/12/rvit_nature_mountains-1600.jpg 1600w, 
                /cms-static-assets/2022/12/rvit_nature_mountains.jpg 2508w" class="image-5">
              </div>
              <div class="services-bottom w-clearfix">
                <router-link to="/job-categories/round-valley-indian-tribes-jobs" class="link-block-12 w-inline-block">
                  <div style="background-color:#4ca5ee" class="percentage blog blue">RVIT Jobs</div>
                </router-link>
                <router-link to="/job-categories/round-valley-indian-tribes-jobs" class="blog-tittle-black">RVIT Jobs</router-link>
                <p class="blog-info">Please see the following page for a list of the current available Round Valley Indian Tribes jobs</p>
                <router-link to="/job-categories/round-valley-indian-tribes-jobs" class="link-4 right">Job Listings</router-link>
              </div>
            </div>
          </div>
          <div role="listitem" class="w-dyn-item w-col w-col-3">
            <div class="services-wrapper in-blog">
              <div>
                <img src="/cms-static-assets/2022/12/rvit_gym.jpg" alt="Abstract blurry gym" sizes="(max-width: 479px) 100vw, (max-width: 767px) 92vw, 22vw" 
                srcset="/cms-static-assets/2022/12/rvit_gym-800.jpg 800w, 
                /cms-static-assets/2022/12/rvit_gym.jpg 847w" class="image-5">
              </div>
              <div class="services-bottom w-clearfix">
                <router-link to="/job-categories/round-valley-indian-health-center-inc-jobs" class="link-block-12 w-inline-block">
                  <div style="background-color:#8a239d" class="percentage blog blue">Health</div>
                </router-link>
                <router-link to="/job-categories/round-valley-indian-health-center-inc-jobs" class="blog-tittle-black">Health Center, Inc Jobs</router-link>
                <p class="blog-info">Please see the following page for a list of the current available Round Valley Indian Tribes jobs</p>
                <router-link to="/job-categories/round-valley-indian-health-center-inc-jobs" class="link-4 right">Job Listings</router-link>
              </div>
            </div>
          </div>
          <div role="listitem" class="w-dyn-item w-col w-col-3">
            <div class="services-wrapper in-blog">
              <div>
                <img src="/cms-static-assets/2022/12/rvit_neighborhood.jpg" alt="" sizes="(max-width: 479px) 100vw, (max-width: 767px) 92vw, 22vw" 
                srcset="/cms-static-assets/2022/12/rvit_neighborhood-500.jpg 500w, 
                /cms-static-assets/2022/12/rvit_neighborhood-800.jpg 800w, 
                /cms-static-assets/2022/12/rvit_neighborhood.jpg 848w" class="image-5">
              </div>
              <div class="services-bottom w-clearfix">
                <router-link to="/job-categories/round-valley-indian-housing-authority-jobs" class="link-block-12 w-inline-block">
                  <div style="background-color:#c68e55" class="percentage blog blue">Housing</div>
                </router-link>
                <router-link to="/job-categories/round-valley-indian-housing-authority-jobs" class="blog-tittle-black">Housing Authority Jobs</router-link>
                <p class="blog-info">Please see the following page for a list of the current available Round Valley Indian Tribes jobs</p>
                <router-link to="/job-categories/round-valley-indian-housing-authority-jobs" class="link-4 right">Job Listings</router-link>
              </div>
            </div>
          </div>
          <div role="listitem" class="w-dyn-item w-col w-col-3">
            <div class="services-wrapper in-blog">
              <div>
                <img src="/cms-static-assets/2022/12/rvit_gaming.jpg" alt="Dealer at table shuffling cards" class="image-5">
              </div>
              <div class="services-bottom w-clearfix">
                <router-link to="/job-categories/hidden-oaks-casino-jobs" class="link-block-12 w-inline-block">
                  <div style="background-color:#ea4025" class="percentage blog blue">Casino</div>
                </router-link>
                <router-link to="/job-categories/hidden-oaks-casino-jobs" class="blog-tittle-black">Hidden Oaks Casino</router-link>
                <p class="blog-info">Please see the following page for a list of the current available Round Valley Indian Tribes jobs</p>
                <router-link to="/job-categories/hidden-oaks-casino-jobs" class="link-4 right">Job Listings</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div v-if="showCourtDates" class="section wf-section">
    <div class="full-container w-container">
      <div class="w-row">
        <div class="w-col w-col-5 w-col-stack">
          <div class="section-tittle-block left">
            <h1 class="heading-3">Enrolled Tribal Member Certification 3540</h1>
            <div class="separator-wrapper left padding">
              <div class="small-separator center red"></div>
              <div class="small-separator"></div>
              <div class="small-separator"></div>
            </div>
            <!-- TODO: This link is broken. Original file doesn't exist -->
            <p class="paragraph left">Fill out the <a href="https://rvit.org/downloads/Enrolled%20Tribal%20Member%20Certification%203540%20for%202019%20tax%20year.pdf">Enrolled Tribal Member Certification</a> form and mail it with your taxes to prove to the state that you're an enrolled Tribal Member and may be exempt from (CA) State Taxes </p>
          </div>
        </div>
        <div class="w-col w-col-7 w-col-stack">
          <div class="w-row">
            <div class="w-col w-col-6">
              <div class="causes-link-wrapper reverse">
                <a href="#" class="image-case-link less w-inline-block"></a>
                <div class="iteam-block donation">
                  <h4 class="features-tittle causes">Court Calendars</h4>
                </div>
                <div class="donation-range-2 raised">Please check the following for court calendars. </div>
                <a href="https://courts.rvit.org/court.calendar.php" class="button move-up no-hover top w-button">Learn more</a>
              </div>
            </div>
            <div class="w-col w-col-6">
              <div class="causes-link-wrapper reverse">
                <a href="#" class="image-case-link less black w-inline-block"></a>
                <div class="iteam-block donation">
                  <h4 class="features-tittle causes">Water Quality</h4>
                </div>
                <div class="donation-range-2 raised">View this year's Public Water System (PWS) Water Quality Reports.</div>
                <a href="https://www.rvit.org/program-directory/water-association" class="button move-up no-hover top w-button">Learn more</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer wf-section">
    <div class="full-container footer">
      <div class="center-block">
        <a href="#" class="link-block footer w-inline-block">
          <img src="@images/logo_rvit_no_center.svg" width="120" alt="">
        </a>
        <div class="sub-tittle">Round Valley Indian Tribes</div>
        <div class="footer-divider"></div>
        <div class="top-space-block _30 center">
          <p class="paragraph-2">© Round Valley Indian Tribes, Located 1 Mile North of Covelo in Round Valley • 77826 Covelo Road • Covelo • California • 95428 <br>‍ </p>
          <p class="paragraph-3">Administration Phone: <a href="tel:(707)983-6126" class="top-left-text dark link">(707) 983-6126</a> / </p>
          <p class="paragraph-3 hire-me">Administration Fax:</p>
          <a href="tel:(707)983-6128" class="top-left-text dark link">(707) 983-6128</a>
        </div>
        <div class="center-device center">
          <a href="#" class="social-icon w-inline-block"></a>
          <a href="#" class="social-icon twitter w-inline-block"></a>
          <a href="#" class="social-icon instagram w-inline-block"></a>
          <a href="#" class="social-icon whatsapp w-inline-block"></a>
        </div>
      </div>
    </div>
  </div>

</template>