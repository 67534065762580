import { home, rvitpage } from '@project/api/rvitpage/v1'
import { about } from '@project/api/about/v1'
import { jobPage } from '@project/api/job-page/v1'


export default {
  async home() {
    return await home()
  },
  async about() {
    return await about()
  },
  async jobPage(slug) {
    const jp = await jobPage(slug)
    return jp
  },
  async rvitEvent(slug) {
    const rp = await rvitpage(slug, 'events')
    return rp
  },
  async rvitProgram(slug) {
    const rp = await rvitpage(slug, 'programs')
    return rp
  },
  async rvitGovernment(slug) {
    const rp = await rvitpage(slug, 'government')
    return rp
  },
  async contactUs() {
    const cU = await rvitpage('contact-us','none')
    return cU
  },
  async rvitProgramDirectory(slug) {
    const rp = await rvitpage(slug, 'program-directory')
    return rp
  },
}

