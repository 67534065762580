import { ButtonAlignCenter, ButtonFlashRadial, ButtonIconPositionHugLeft, ButtonIconPositionShiftLeft, ButtonIconActiveHeight } from '@kit/components/IconButton.vue'

export const baseGalleryButton = {
  foreground: {
    active:   "#FFFFFF",
    inactive: "#AAAAAA",
    flash:    "#FFFFFF",
    loading:  "#FFFFFF" 
  },
  background: { 
    padding:      "10px",
    none: true    
  },
  iconPosition: ButtonIconPositionShiftLeft,
  align: ButtonAlignCenter,
  allowPropagate: false,
  loader: {
    fill:"#CCCCCC", 
    wheel: {
      gif:"Spinner-0.4s-310px-ffffff-transparent",
      spinnerStyle:"width:120%; height:120%; top:-10%; left:-10%; background-size:contain; background-position:center; background-repeat:no-repeat; position:absolute;"
    }
  }
}

export const roundCloseButton = {
  ...baseGalleryButton, 
  icon:"rounded-close-x_50", 
  iconDimension: {
    style: ButtonIconActiveHeight,
    active: "20px"
  },
}

export const leftButton = {
  ...baseGalleryButton,
  icon:"rounded-single-chevron-left",
  iconDimension: {
    style: ButtonIconActiveHeight,
    active: "40px"
  }
}

export const rightButton = {
  ...baseGalleryButton,
  icon:"rounded-single-chevron-right",
  iconDimension: {
    style: ButtonIconActiveHeight,
    active: "40px"
  }
}









  