export const routes = [
  {
    name: 'root',
    path: '/',
    component: () => import(/* webpackChunkName: "root" */'@pages/Home.vue'),
  },
  // {
  //   name: 'forms',
  //   path: '/government/documents-forms',
  //   component: () => import(/* webpackChunkName: "forms" */'@pages/Forms.vue'),
  // },
  {
    name:"contact-us",
    path:"/contact-us",
    component: () => import(/* webpackChunkName: "contact-us" */'@pages/ContactUs.vue'),
  },
  {
    name: 'about-us',
    path: '/about/about-us',
    component: () => import(/* webpackChunkName: "about-us" */'@pages/about/AboutUs.vue'),
  },
  {
    name: 'po-e-kan',
    path: '/about/po-e-kan',
    component: () => import(/* webpackChunkName: "po-e-kan" */'@pages/about/PoEKan.vue'),
  },
  {
    name: 'cell-tower-project',
    path: '/about/cell-tower-project',
    component: () => import(/* webpackChunkName: "cell-tower-project" */'@pages/about/CellTowerProject.vue'),
  },
  {
    name: 'covid-19-information',
    path: '/about/covid-19-information',
    component: () => import(/* webpackChunkName: "covid-19-information" */'@pages/about/Covid19Information.vue'),
  },
  {
    name: 'tower-location-voting',
    path: '/about/tower-location-voting',
    component: () => import(/* webpackChunkName: "tower-location-voting" */'@pages/about/TowerLocationVoting.vue'),
  },
  {
    name: 'admin-site-photos',
    path: '/cell-phone-tower-project/admin-site-photos',
    component: () => import(/* webpackChunkName: "admin-site-photos" */'@pages/about/cell-phone-tower-project/AdminSitePhotos.vue'),
  },
  {
    name: 'brown-site-photos',
    path: '/cell-phone-tower-project/brown-site-photos',
    component: () => import(/* webpackChunkName: "brown-site-photos" */'@pages/about/cell-phone-tower-project/BrownSitePhotos.vue'),
  },
  {
    name: 'equipment-photos',
    path: '/cell-phone-tower-project/equipment-photos',
    component: () => import(/* webpackChunkName: "equipment-photos" */'@pages/about/cell-phone-tower-project/EquipmentPhotos.vue'),
  },
  {
    name: 'housing-site-photos',
    path: '/cell-phone-tower-project/housing-site-photos',
    component: () => import(/* webpackChunkName: "housing-site-photos" */'@pages/about/cell-phone-tower-project/HousingSitePhotos.vue'),
  },
  {
    name: 'jobs-general-information',
    path: '/jobs-rvit/jobs-general-information',
    component: () => import(/* webpackChunkName: "jobs-general-information" */'@pages/jobs-rvit/JobsGeneralInformation.vue'),
  },
  // {
  //   name: 'round-valley-indian-tribes-jobs',
  //   path: '/job-categories/round-valley-indian-tribes-jobs',
  //   component: () => import(/* webpackChunkName: "round-valley-indian-tribes-jobs" */'@pages/job-categories/RVITJobs.vue'),
  // },
  // {
  //   name: 'round-valley-indian-health-center-inc-jobs',
  //   path: '/job-categories/round-valley-indian-health-center-inc-jobs',
  //   component: () => import(/* webpackChunkName: "round-valley-indian-health-center-inc-jobs" */'@pages/job-categories/RVIHCJobs.vue'),
  // },
  // {
  //   name: 'round-valley-indian-housing-authority-jobs',
  //   path: '/job-categories/round-valley-indian-housing-authority-jobs',
  //   component: () => import(/* webpackChunkName: "round-valley-indian-housing-authority-jobs" */'@pages/job-categories/RVIHAJobs.vue'),
  // },
  {
    name: 'job-categories',
    path: '/job-categories/:slug',
    component: () => import(/* webpackChunkName: "job-categories" */'@project/components/RVITJobs.vue'),
  },
  {
    name: 'job-categories-job',
    path: '/job-categories/:slug/:job',
    component: () => import(/* webpackChunkName: "job-categories-job" */'@project/components/RVITJob.vue'),
  },
  {
    name: 'events',
    path: '/events/:slug',
    component: () => import(/* webpackChunkName: "rvit-events" */'@project/components/RVITEvent.vue'),
  },
  {
    name:"programs",
    path: '/programs/:slug',
    component: () => import(/* webpackChunkName: "programs" */'@project/components/RVITProgram.vue'),
  },
  {
    name:"program-directory",
    path: '/program-directory/:slug',
    component: () => import(/* webpackChunkName: "programs" */'@project/components/RVITProgramDirectory.vue'),
  },
  // {
  //   name: 'observed-holidays',
  //   path: '/events/observed-holidays',
  //   component: () => import(/* webpackChunkName: "observed-holidays" */'@pages/events/ObservedHolidays.vue'),
  // },
  // {
  //   name:"child-care",
  //   path: '/program-directory/child-care',
  //   component: () => import(/* webpackChunkName: "child-care" */'@pages/programs/ChildCare.vue'),
  // },
  // {
  //   name:"environmental-protection-agency",
  //   path: '/program-directory/environmental-protection-agency',
  //   component: () => import(/* webpackChunkName: "environmental-protection-agency" */'@pages/programs/EPA.vue'),
  // },
  // {
  //   name:"head-start",
  //   path: '/program-directory/head-start',
  //   component: () => import(/* webpackChunkName: "head-start" */'@pages/programs/HeadStart.vue'),
  // },
  // {
  //   name:"icwa",
  //   path: '/program-directory/icwa',
  //   component: () => import(/* webpackChunkName: "icwa" */'@pages/programs/ICWA.vue'),
  // },
  // {
  //   name:"nadvsap-dv",
  //   path: '/program-directory/nadvsap-dv',
  //   component: () => import(/* webpackChunkName: "nadvsap-dv" */'@pages/programs/NADVSAP.vue'),
  // },
  // {
  //   name:"natural-resources-department",
  //   path: '/program-directory/natural-resources-department',
  //   component: () => import(/* webpackChunkName: "natural-resources-department" */'@pages/programs/NaturalResourcesDepartment.vue'),
  // },
  // {
  //   name:"program-directory",
  //   path: '/programs/program-directory',
  //   component: () => import(/* webpackChunkName: "program-directory" */'@pages/programs/ProgramDirectory.vue'),
  // },
  // {
  //   name:"real-estate-department",
  //   path: '/program-directory/real-estate-department',
  //   component: () => import(/* webpackChunkName: "real-estate-departments" */'@pages/programs/RealEstateDepartment.vue'),
  // },
  // {
  //   name:"rvihc",
  //   path: '/program-directory/rvihc',
  //   component: () => import(/* webpackChunkName: "rvihc" */'@pages/programs/RVIHC.vue'),
  // },
  // {
  //   name:"senior-center",
  //   path: '/program-directory/senior-center',
  //   component: () => import(/* webpackChunkName: "senior-center" */'@pages/programs/SeniorCenter.vue'),
  // },
  // {
  //   name:"roads-transportation-department",
  //   path: '/program-directory/roads-transportation-department',
  //   component: () => import(/* webpackChunkName: "roads-transportation-department" */'@pages/programs/TransportationDepartment.vue'),
  // },
  // {
  //   name:"tribal-historic-preservation-office",
  //   path: '/program-directory/tribal-historic-preservation-office',
  //   component: () => import(/* webpackChunkName: "tribal-historic-preservation-office" */'@pages/programs/TribalHistoricPreservationOffice.vue'),
  // },
  // { 
  //   name:"tribal-police",
  //   path: '/program-directory/tribal-police',
  //   component: () => import(/* webpackChunkName: "tribal-police" */'@pages/programs/TribalPolice.vue'),
  // },
  // {
  //   name:"water-association",
  //   path: '/program-directory/water-association',
  //   component: () => import(/* webpackChunkName: "water-association" */'@pages/programs/WaterAssociation.vue'),
  // },
  {
    name:"enterprise-info",
    path: '/enterprises/enterprises-info',
    component: () => import(/* webpackChunkName: "enterprises-info" */'@pages/enterprises/EnterprisesInfo.vue'),
  },

  {
    name:"government",
    path:"/government/:slug",
    component: () => import(/* webpackChunkName: "government" */'@components/RVITGovernment.vue'),
  },

  
  // {
  //   name:"agendas",
  //   path:"/government/agendas",
  //   component: () => import(/* webpackChunkName: "agendas" */'@pages/government/Agendas.vue'),
  // },
  // {
  //   name:"committees-boards",
  //   path:"/government/committees-boards",
  //   component: () => import(/* webpackChunkName: "committees-boards" */'@pages/government/CommitteesBoards.vue'),
  // },
  // {
  //   name:"documents-forms",
  //   path:"/government/documents-forms",
  //   component: () => import(/* webpackChunkName: "documents-forms" */'@pages/government/DocumentsForms.vue'),
  // },
  // {
  //   name:"minutes",
  //   path:"/government/minutes",
  //   component: () => import(/* webpackChunkName: "minutes" */'@pages/government/Minutes.vue'),
  // },
  // {
  //   name:"tribal-administration",
  //   path:"/government/tribal-administration",
  //   component: () => import(/* webpackChunkName: "tribal-administration" */'@pages/government/TribalAdministration.vue'),
  // },
  // {
  //   name:"tribal-council",
  //   path:"/government/tribal-council",
  //   component: () => import(/* webpackChunkName: "tribal-council" */'@pages/government/TribalCouncil.vue'),
  // },
  // {
  //   name:"tribal-court",
  //   path:"/tribal-court/:page",
  //   meta: { paramsMappedToMultipleSections: true },
  //   component: () => import(/* webpackChunkName: "tribal-court" */'@pages/tribal-court/TribalCourt.vue'),
  // },

]