<script>
import { defineComponent, watch, onMounted, onUnmounted, ref, inject } from 'vue'
import '@css/round-valley-indian-tribes.df049ad5f.css'
import '@css/rvit.css'
import '@kit/assets/css/basic.css'
import '@kit/assets/css/superbox-core-x.css'
import '@css/extra.css'
import NavBar from '@components/NavBar.vue'
import Footer from '@components/Footer.vue'
//import SideNavDrawer from '@components/SideNavDrawer.vue'
import { inBrowser, SSR, incrementResize, setMobileThresholdFromApp } from '@kit/utils/EnvironmentHelper'
import { useStore } from 'vuex'
import { addEvent } from '@kit/utils/EventBus'
import { pathname } from '@kit/utils/Formats'
import '@images/favicon.png'

const wCtx = typeof window != 'undefined' ? window : null

export default defineComponent({
  name: "RVITApp",
  setup() {

    let dCache = null
    let dInterval = null
    const store = useStore()
    const hydrate = inject("hydrate")

    const mediaQuery = wCtx ? wCtx.matchMedia('(max-width : 960px)') : null
    
    const atThreshold = ref(false)

    //const modXCSS = _modXCSS || []
    //const modXJS = _modXJS || []

    const debounced = (value) => {
      atThreshold.value = value
    }

    const debouncer = (value) => {
      if(!dInterval) {
        debounced(value)

        dInterval = setInterval(() => {
          if(dCache !== null) {
            debounced(dCache)
            dCache = null
          } else {
            clearInterval(dInterval)
            dInterval = null
          }
        },500)
        
      } else {
        dCache = value
      }
    }

    const screenResized = () => {
      incrementResize()
      const atThreshold = mediaQuery.matches
      setMobileThresholdFromApp(atThreshold)
      debouncer(mediaQuery.matches)
    }


    onMounted(async() => {
      if(wCtx) {

        addEvent("navigateToNewPage", ( info ) => {
          if(info.resetScrollPosition) {
            wCtx.scrollTo(0,0)
          }
        })

        wCtx.addEventListener('resize', screenResized)
        screenResized()

      }
    })
    onUnmounted(() => {
      if(inBrowser) {
        wCtx.removeEventListener('resize', screenResized)
      }
    })

    const htmlClasses = "w-mod-js wf-montserrat-n1-active wf-montserrat-i1-active wf-montserrat-n2-active wf-montserrat-i2-active wf-montserrat-n3-active wf-montserrat-i3-active wf-montserrat-n4-active wf-montserrat-i4-active wf-montserrat-n5-active wf-montserrat-i5-active wf-montserrat-n6-active wf-montserrat-i6-active wf-montserrat-n7-active wf-montserrat-i7-active wf-montserrat-n8-active wf-montserrat-i8-active wf-montserrat-n9-active wf-montserrat-i9-active wf-lato-n1-active wf-lato-i1-active wf-lato-n3-active wf-lato-i3-active wf-lato-n4-active wf-lato-i4-active wf-lato-n7-active wf-lato-i7-active wf-lato-n9-active wf-lato-i9-active wf-merriweather-n3-active wf-merriweather-i3-active wf-merriweather-n4-active wf-merriweather-i4-active wf-merriweather-n7-active wf-merriweather-i7-active wf-merriweather-n9-active wf-merriweather-i9-active wf-opensans-n3-active wf-opensans-i3-active wf-opensans-n4-active wf-opensans-i4-active wf-opensans-n6-active wf-opensans-i6-active wf-opensans-n7-active wf-opensans-i7-active wf-opensans-n8-active wf-opensans-i8-active wf-poppins-n1-active wf-poppins-n2-active wf-poppins-n3-active wf-poppins-n4-active wf-poppins-n5-active wf-poppins-n6-active wf-poppins-n7-active wf-poppins-n8-active wf-poppins-n9-active wf-playfairdisplay-n4-active wf-playfairdisplay-n5-active wf-playfairdisplay-n6-active wf-playfairdisplay-n7-active wf-playfairdisplay-n8-active wf-playfairdisplay-n9-active wf-playfairdisplay-i4-active wf-playfairdisplay-i5-active wf-playfairdisplay-i6-active wf-playfairdisplay-i7-active wf-playfairdisplay-i8-active wf-playfairdisplay-i9-active wf-active w-mod-ix"

    hydrate({
      metadata(meta) {


        meta.addMetaItem([
          { charset:'utf-8' },
          { name:'viewport', content: 'width=device-width, initial-scale=1.0' },
          { "http-equiv":'X-UA-Compatible', content: 'IE=edge'},
          { property:"og:type", content:"website" },
        ])
        meta.addHTMLAttribute({ name: "data-wf-site", value:"5f8ef8b90a7fac3930daf289" })
        meta.addHTMLAttribute({ name: "data-wf-page", value:"5f8ef8b90a7fac2751daf28a" })
        meta.addHTMLAttribute({ name: "data-wf-domain", value:"www.rvit.org" })
        meta.addHTMLAttribute({ name: "lang", value:"en" })
        meta.addHTMLAttribute({ name: "class", value:htmlClasses})
        meta.addAppAttribute({ name: "class", value: "page-wrapper" })
        meta.addBodyAttribute({ name:"class", value: "wf-16ehj8u"})
        meta.addBodyAttribute({ name:"data-wf-unified-tokens", value:"wf-16ehj8u"})
        meta.addFavIcon(pathname('@images/favicon.png'))
      },
      extraJS:[
        { head: true, src: "https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js" },
        { 
          head:true, 
          code: `WebFont.load({ google: { families: ["Montserrat:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic","Lato:100,100italic,300,300italic,400,400italic,700,700italic,900,900italic","Merriweather:300,300italic,400,400italic,700,700italic,900,900italic","Open Sans:300,300italic,400,400italic,600,600italic,700,700italic,800,800italic","Poppins:100,200,300,regular,500,600,700,800,900","Playfair Display:regular,500,600,700,800,900,italic,500italic,600italic,700italic,800italic,900italic"]  }});`
        },
        {
          head:true,
          code: `!function(o,c){var n=c.documentElement,t=" w-mod-";n.className+=t+"js",("ontouchstart"in o||o.DocumentTouch&&c instanceof DocumentTouch)&&(n.className+=t+"touch")}(window,document);`
        }
      ],
      externalCSS: ["https://fonts.googleapis.com/css?family=Montserrat:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic%7CLato:100,100italic,300,300italic,400,400italic,700,700italic,900,900italic%7CMerriweather:300,300italic,400,400italic,700,700italic,900,900italic%7COpen+Sans:300,300italic,400,400italic,600,600italic,700,700italic,800,800italic%7CPoppins:100,200,300,regular,500,600,700,800,900%7CPlayfair+Display:regular,500,600,700,800,900,italic,500italic,600italic,700italic,800italic,900italic"]
    })

    watch(atThreshold, (newVal, oldVal) => {
      if(newVal && !oldVal) {
        store.dispatch("screenThreshold", newVal)
      } else 
      if(!newVal && oldVal) {
        store.dispatch("screenThreshold", newVal)
      }
    }, {immediate:true})

    return { inBrowser, SSR }

  },

  components: {
    NavBar,
    Footer,
    //SideNavDrawer
  },
});
</script>

<template>
    <NavBar/>
    <router-view/>  
    <Footer/>
</template>

